import { Injectable } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { BotGuild } from '../models/bot/guild';
import { BotResourceService } from './bot-resource.service';
import { Observable } from 'rxjs/internal/Observable';
import { from, throwError } from 'rxjs';
import { catchError, shareReplay } from 'rxjs/operators';
import { PermissionsService } from 'src/app/services/permissions.service';

@Injectable({
  providedIn: 'root'
})
export class GuildService {
  private id: string;
  guild: BotGuild;
  raidOrganizerGuild: BotGuild = {
    id: '658007285205041153',
    name: 'Raid Organizer',
    icon: '36fcdbe85f897fb55c62a4ecf0ea019a',
    owner: false,
    permissions: 0,
    features: [],
    permissions_new: '0',
    joined: false,
    has_premium: false,
    lifetime_premium: false,
    channels: [],
    signup_templates: [],
    organizer: false,
    limits: {"max_custom_templates": 0, "max_event_channels": 0, "max_event_templates": 0, "max_repeating_events": 0}
  }
  guildObservable: Observable<BotGuild>;

  constructor(
    private botService: BotResourceService,
    private oauthService: OAuthService,
    private permissionsService: PermissionsService,

  ) { }

  getGuildData(id: string): Observable<BotGuild> {
    if (!this.guildObservable || this.id !== id) {
      this.guildObservable = from(this.botService.getGuild(id)).pipe(
        catchError(error => {
          if (error.status === 404) {
            this.botAccess(id);
          }
          return throwError(error);
        }),
        shareReplay(1)
      );
    }
    this.id = id;
    return this.guildObservable;
  }


  botAccess(id: string): void {
    this.oauthService.scope = 'applications.commands bot';
    this.oauthService.initCodeFlow(null, {
      permissions: this.permissionsService.getBotAccessPermissions(),
      guild_id: id,
    });
  }
}
