<div class="pt-3 ml-3 text-center">
  <h1>Event Creation</h1>
  <p>This is the web based version of the <code>/event create</code> command.</p>
</div>

<div *ngIf="!guild">
  <p>Loading...</p>
</div>

<div class="row" *ngIf="guild">
  <div class="col-md-12 col-lg-7">
    <h3 class="pb-3">Event Setup</h3>
    <!-- Select channel and event template -->
    <form [formGroup]="eventForm" (ngSubmit)="onSubmit()">
      <div class="row pr-3">
        <div class="col-12 col-md-6 pr-0">
          <mat-form-field class="full-width-container">
            <mat-label>Event/LFG Channel </mat-label>
            <mat-select id="eventChannel" name="eventChannel" formControlName="eventChannel">
              <ng-container *ngFor="let channel of allowedChannels">
                <mat-option [value]="channel.id">
                  {{ channel.name }}
                </mat-option>
              </ng-container>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-12 col-md-6 pr-0">
          <mat-form-field class="full-width-container">
            <mat-label>Event Template</mat-label>
            <mat-select id="eventTemplate" name="eventTemplate" formControlName="eventTemplate">
              <ng-container *ngFor="let template of eventTemplates">
                <mat-option [value]="template.e_identifier">
                  {{ template.title }}
                </mat-option>
              </ng-container>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <h3 class="pb-3 pt-3">Event Details</h3>
      <!-- Title -->
      <mat-form-field class="full-width-container">
        <mat-label>Title </mat-label>
        <input matInput formControlName="title">
        <mat-error *ngIf="eventForm.get('title').hasError('required')">
          Title is required
        </mat-error>
        <mat-error *ngIf="eventForm.get('title').errors?.maxlength">Title is too long (max.
          60)
        </mat-error>
        <mat-hint align="end">{{eventForm.get('title').value.length}} / 60</mat-hint>
      </mat-form-field>

      <div class="row pr-3">
        <!-- Start Date and Time -->
        <div class="col-12 col-md-6 pr-0">
          <mat-form-field class="full-width-container">
            <mat-label>Start Date and Time </mat-label>
            <input matInput [ngxMatDatetimePicker]="startPicker" placeholder="Choose a date" formControlName="start"
              [min]="startMinDate" [max]="maxDate">
            <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
            <ngx-mat-datetime-picker #startPicker [touchUi]="touchUi" [enableMeridian]="enableMeridian"
              [showSeconds]="false">
            </ngx-mat-datetime-picker>
            <mat-error *ngIf="eventForm.get('start').hasError('required')">
              Start Date and Time is required
            </mat-error>
          </mat-form-field>
        </div>
        <!-- End Date and Time -->
        <div class="col-12 col-md-6 pr-0">
          <mat-form-field class="full-width-container">
            <mat-label>End Date and Time</mat-label>
            <input matInput [ngxMatDatetimePicker]="endPicker" placeholder="Choose a date" formControlName="end"
              [min]="endMinDate" [max]="maxDate">
            <mat-datepicker-toggle matSuffix [for]="endPicker"></mat-datepicker-toggle>
            <ngx-mat-datetime-picker #endPicker [touchUi]="touchUi" [enableMeridian]="enableMeridian">
            </ngx-mat-datetime-picker>
            <mat-error *ngIf="eventForm.get('end').hasError('matDatetimePickerMin')">
              End Date / Time needs to be after Start Date / Time
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <!-- Description -->
      <mat-form-field class="full-width-container">
        <mat-label>Description</mat-label>
        <textarea matInput formControlName="description"></textarea>
        <mat-error *ngIf="eventForm.get('description').errors?.maxlength">Description is too long (max.
          700)
        </mat-error>
        <mat-hint align="end">{{eventForm.get('description').value.length}} / 700</mat-hint>
      </mat-form-field>

      <!-- Signup Template -->
      <mat-form-field class="full-width-container">
        <mat-label>Signup Template </mat-label>
        <mat-select id="signupTemplate" formControlName="signupTemplate">
          <ng-container *ngFor="let tempalte of signupTemplates">
            <mat-option [value]="tempalte.identifier">
              {{ tempalte.name }}
            </mat-option>
          </ng-container>
        </mat-select>
      </mat-form-field>

      <!-- Text -->
      <mat-form-field class="full-width-container">
        <mat-label>Text</mat-label>
        <textarea matInput formControlName="text"></textarea>
        <mat-error *ngIf="eventForm.get('text').errors?.maxlength">Footer is too long (max.
          2000)
        </mat-error>
        <mat-hint align="end">{{eventForm.get('text').value.length}} / 2000</mat-hint>
      </mat-form-field>

      <!-- Footer Text -->
      <mat-form-field class="full-width-container">
        <mat-label>Footer Text (Premium)</mat-label>
        <textarea matInput formControlName="footerText"></textarea>
        <mat-error *ngIf="eventForm.get('footerText').errors?.maxlength">Footer is too long (max.
          1900)
        </mat-error>
        <mat-hint align="end">{{eventForm.get('footerText').value.length}} / 1900</mat-hint>
      </mat-form-field>

      <!-- Footer Icon -->
      <mat-form-field class="full-width-container">
        <mat-label>Footer Icon (Premium)</mat-label>
        <input matInput formControlName="footerIcon">
      </mat-form-field>

      <!-- Embed Image -->
      <mat-form-field class="full-width-container">
        <mat-label>Embed Image URL (Premium)</mat-label>
        <input matInput formControlName="embedImage">
      </mat-form-field>

      <!-- Embed Thumbnail -->
      <mat-form-field class="full-width-container">
        <mat-label>Embed Thumbnail URL (Premium)</mat-label>
        <input matInput formControlName="embedThumbnail">
      </mat-form-field>

      <!-- Submit -->
      <mat-card-actions class="full-width-container">
        <button mat-raised-button color="primary" type="submit" [disabled]="!eventForm.valid || creationInProgress">
          Create Event
        </button>
        <div *ngIf="creationInProgress" class="loading-indicator">
          <mat-spinner diameter="20"></mat-spinner>
          <span>Creating event...</span>
        </div>
      </mat-card-actions>
    </form>
  </div>

  <mat-divider [vertical]="true"></mat-divider>

  <div class="col-md-12 col-lg-4 mt-md-0 mt-3">
    <h3 class="pb-3">Preview</h3>
    <app-embed-preview></app-embed-preview>
  </div>
</div>
